import React from 'react'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import Share from "../components/share/sharing";
import {metadata} from "../../config";
import loadable from "@loadable/component";
import Disclaimer from "../components/disclaimer/disclaimer";
import {
    adDisplayDelay,
    delayMultiplicationFactor,
    formatDate, googleScriptLoadingDelay,
    isBrowser, setStreamData
} from "../utils/articleUtil";
import {orderBy as _orderBy} from 'lodash';
import {isMobile} from "react-device-detect";
import Helmet from "react-helmet";
import consts from "../constants/consts";
import Navigate from "../components/navigate";
import FbComments from "../components/fbComments";

import '../css/components/_article.scss';
import {withPrefix} from "gatsby";
import {articlePageAds, videoArticleAds} from "../utils/adUnits";
import { addTimeout } from '../utils/timeManager';

const ArticlePopularPosts = loadable(() => import("../components/popularPosts/articlePopular"));
const WhatNext = loadable(() => import("../components/whatNext"));
const WhatNextMobile = loadable(() => import("../components/whatNext/mobileRecommended"));

class VideoArticle extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.pageContext.article;
        this.url = `${metadata.url}/${props.pageContext.url}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.treeParent !== null);
        setStreamData({
            subCategory: this.subCategory.title,
            category: this.subCategory.treeParent.title,
            article: this.data.heading,
            author: this.data.author.name
        });
        this.longAdCount = 0;
        this.state = {
            timeouts: []
        }

        this.getRecommendedPosts();

        this.schemaArticle = {
            '@context': 'https://schema.org',
            '@type': this.data.json && this.data.json.faq ? 'FAQPage' : 'Article',
            author: {
                '@type': 'Person',
                name: this.data.author.name,
                url: `${metadata.url}/authors/${this.data.author.slug}`
            },
            "publisher": {
                "@type": "Organization",
                "name": metadata.defaultTitle,
                "logo": {
                    "@type": "ImageObject",
                    "url": `${metadata.url}/images/logos/logo.png`
                }
            },
            datePublished: this.data.publishedAt,
            description: this.data.excerpt,
            headline: this.data.seo.title,
            url: this.url,
            name: this.data.seo.title,
            wordCount: this.props.pageContext.wordCount,
            articleSection: this.subCategory.title
        }
        if (this.data.seo.image) {
            this.schemaArticle.image = {
                '@type': 'ImageObject',
                url: this.data.seo.image.url,
            }
        }
        if (this.data.json && this.data.json.faq) {
            this.schemaArticle.mainEntity = [];
            this.data.json.faq.forEach(faq => {
                this.schemaArticle.mainEntity.push({
                    "@type": "Question",
                    "name": faq.title,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": faq.text
                    }
                });
            })
        } else {
            this.schemaArticle.mainEntityOfPage = this.url;
        }
    }

    getRecommendedPosts() {
        this.recommendedPosts = [];
        this.popularPosts = [];

        const slugs = [];
        let posts = [];
        const relatedArticlesObject = this.props.pageContext.relatedArticles
        posts = posts
            .concat(relatedArticlesObject.tags10 || [])
            .concat(relatedArticlesObject.tags9 || [])
            .concat(relatedArticlesObject.tags8 || [])
            .concat(relatedArticlesObject.tags7 || [])
            .concat(relatedArticlesObject.tags6 || [])
            .concat(relatedArticlesObject.tags5 || [])
            .concat(relatedArticlesObject.tags4 || [])
            .concat(relatedArticlesObject.tags3 || [])
            .concat(relatedArticlesObject.tags2 || [])
            .concat(relatedArticlesObject.tags1 || [])
            .concat(relatedArticlesObject.sameCategory || [])
            .concat(relatedArticlesObject.random || []);

        const articleCount = isMobile ? 0 : 4;

        for (let i = 0; i < posts.length && !isMobile; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.popularPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.popularPosts.length === 5) {
                break;
            }
        }

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.recommendedPosts.push(post);
                slugs.push(post.slug);
            }
            if (this.recommendedPosts.length === articleCount) {
                break;
            }
        }
    }

    getContent() {
        let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");
        return content;
    }

    getUrlForFacebook() {
        if (isBrowser()) {
            return `${window.location.origin}/${this.props.pageContext.url}`;
        } else {
            return this.url;
        }
    }

    googleScriptLoadingDelay = () => {
        return !document.querySelector('script[src*="ad_2.0.1.js"]') ? consts.SCRIPT_LOADING_TIME_WAIT : 1;
    }

    adDisplayDelay = () => {
        return this.googleScriptLoadingDelay() + 10;
    }

    addGPTScript = () => {
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const gptScript = document.createElement("script");
        gptScript.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        gptScript.async = true;
        headElement.appendChild(gptScript);
        gptScript.onload = () => {
            addTimeout(() => {
                _this.addAdScript();
            });
        }
    }

    addAdScript = () => {
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `${withPrefix('ad_2.0.1.js')}`;
        adScript.type = "text/javascript";
        headElement.appendChild(adScript);
        adScript.onload = () => {
            addTimeout(() => {
                _this.refreshAd();
            }, 200 * this.state.timeoutMultiplier);
        }
    }

    refreshAd = () => {
        addTimeout(() => {
            try {
                window.googletag = window.googletag || {cmd: []};
                googletag.cmd.push(function () {
                    googletag.display("div-pt_incontent1_mobile");
                    googletag.display("div-pt_incontent1_desktop");
                });
                window.googletag.pubads().refresh(null, {changeCorrelator: true});
            } catch (e) {
                console.log('googletag: ' + e);
            }
        }, 100);
    }

    componentDidMount() {

        addTimeout(videoArticleAds, adDisplayDelay() * delayMultiplicationFactor(isMobile));

        const youtubeVideoDisplay = () => {
            const vidDefer = document.getElementsByTagName('iframe');
            for (let i = 0; i < vidDefer.length; i++) {
                if (vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
                }
            }
        }

        if (this.googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
            addTimeout(() => {
                youtubeVideoDisplay();
            }, consts.SCRIPT_LOADING_TIME_WAIT * delayMultiplicationFactor(isMobile));
        } else {
            addTimeout(() => {
                youtubeVideoDisplay();
            }, 1000 * delayMultiplicationFactor(isMobile));
        }
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    render() {
        return <Layout relativePath={this.props?.pageContext?.url}>
            <Helmet>
                {/*<link rel="preload" as="image" href={this.data.cover.responsiveImage.src}
                      imagesrcset={this.data.cover.responsiveImage.webpSrcSet}
                      imagesizes={this.data.cover.responsiveImage.sizes}/>*/}
                <meta name="keywords" content={this.data.tags}/>
                <script type="application/ld+json">{JSON.stringify(this.schemaArticle)}</script>
                {this.props.pageContext.noindex && <meta name="robots" content="noindex"/>}
                <link rel="canonical" href={this.url}/>
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper">
                <div className={"w-full lg:flex"}>
                    <article className="lg:w-[calc(100%-305px)]">
                        <div className={"category-heading-link"}>
                            {this.subCategory.treeParent &&
                            <><Navigate
                                href={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}>{this.subCategory.treeParent.title}</Navigate><span className={"color-denim"}> | </span></>}
                            <Navigate
                                href={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}>{this.subCategory.title}</Navigate>
                        </div>
                        <header className="article-content__header">
                            <h1 className="text-xl lg:text-3xl font-semibold">{this.data.heading}</h1>
                            <p className="text-base lg:text-lg font-arial leading-snug">{this.data.excerpt}</p>
                            <div className="py-2 lg:py-4 flex items-center" data-datocms-noindex>
                                {this.data.author.profilePhoto && <img className="rounded-full mr-2" alt={this.data.author.name} width={80} height={80}
                                                                       src={`${this.data.author.profilePhoto?.url}&w=80&h=80&fit=crop`}/>}
                                <div className={"text-black text-opacity-50"}>
                                    <div>
                                        Curated by <Navigate
                                        href={`/authors/${this.data.author.slug}`} className={"text-denim"}>{this.data.author.name}</Navigate>
                                    </div>
                                    {/*<time>{formatDate(this.data.publishedAt)}</time>*/}
                                    <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                                    {this.data.isSponsored && <span>&nbsp;·&nbsp;sponsored</span>}
                                </div>
                            </div>
                        </header>
                        <div className="ad-container video-article-ad">
                            <div className="advertisement-text">Advertisement</div>
                            <div id="div-pt_incontent1_mobile"></div>
                            <div id="div-pt_incontent1_desktop"></div>
                        </div>
                        <div className="ad-container video-article-ad mb-2" data-datocms-noindex>
                            <div className="advertisement-text">Advertisement</div>
                            <div id="pt_video_article_incontent1"/>
                        </div>
                        <main doc-container="main" className={"relative"}>
                            <div id="article-content" dangerouslySetInnerHTML={{__html: this.getContent()}}/>
                            {!isMobile && <div className={"absolute"} style={{top: 0, left: '-65px'}}>
                                <Share/>
                            </div>}
                        </main>
                        {isMobile && <Share/>}
                        <FbComments url={this.getUrlForFacebook()}/>
                        {this.subCategory.treeParent && !['videos'].includes(this.subCategory.treeParent.slug) && <div className={"lg:mx-6"}>
                            <Disclaimer/>
                        </div>}
                        {!isMobile && <WhatNext title={"WHAT TO WATCH NEXT..."} recommendedPosts={this.recommendedPosts}/>}
                        {isMobile && <WhatNextMobile recommendedPosts={this.recommendedPosts}/>}
                    </article>
                    {!isMobile && <ArticlePopularPosts articles={this.popularPosts}/>}
                </div>
            </main>
        </Layout>
    }
}
export default VideoArticle
