import React from "react";
import '../../css/components/_disclaimer.scss';

const Disclaimer = () => {
    return <div className="disclaimer-container" data-datocms-noindex>The content on pensiontimes.co.uk is for informational and educational
        purposes only and should not be construed as professional financial advice. Should you need such advice, consult
        a licensed financial advisor. Any references to products, offers, rates and services from third parties
        advertised are served by those third parties and are subject to change. We may have financial relationships with
        some of the companies mentioned on this website. We strive to write accurate and genuine reviews and articles,
        and all views and opinions expressed are solely those of the authors</div>
}

export default Disclaimer;